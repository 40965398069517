// Action types
const ADD_CONFIG = 'CONFIG/ Add config'

// Reducer
const config = (state = {}, action) => {
	switch (action.type) {
		case ADD_CONFIG:
			return action.payload
		default:
			return state
	}
}

// Actions
export const addCOnfig = config => {
	return {
		type: ADD_CONFIG,
		payload: config
	}
}

export default config
