import { memo } from 'react'
import { useSelector } from 'react-redux'
const Office = () => {
	const office = useSelector(state => state.office)
	const arr = office.data?.return?.СписокПодразделений?.Подразделение

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				padding: '2rem 1rem',
				gap: '1rem'
			}}
		>
			{arr
				.sort((a, b) => a.Код - b.Код)
				.map((i, k) => (
					<div
						key={k}
						style={{
							display: 'flex',
							flexDirection: 'column',
							padding: '1rem',
							borderRadius: '1rem',
							backgroundColor: '#FFF'
						}}
					>
						<span>
							<b>{i.Наименование}</b>
						</span>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								maxWidth: 400,
								alignSelf: 'end'
							}}
						>
							<span>Руководитель: {i.Руководитель}</span>
							<span>
								Email: <a href={`mailto:${i.Email}`}>{i.Email}</a>
							</span>
							<span>
								Телефон: <a href={`tel:${i.Телефон}`}>{i.Телефон}</a>
							</span>
							<span>Адрес: {i.Адрес}</span>
						</div>
					</div>
				))}
		</div>
	)
}
export default memo(Office)
