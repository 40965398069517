import { DatePicker, Form, Modal } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import makeNumberPretty from '../../../../../heplers/makeNumberPretty'
import { getUserData } from '../../../../../redux/ducks/user'

const PrognozModal = ({ visible, onCancel, title, item }) => {
	const dispatch = useDispatch()
	// antd Form
	const [form] = Form.useForm()

	const dateFormat = 'DD.MM.YYYY'
	const [sum, setSum] = useState(0)
	useEffect(() => {
		if (item) {
			let a =
				+item.тПлатеж.Номинал +
				+item.тПлатеж.Комиссия +
				+item.тПлатеж.Пени +
				+item.тПлатеж.ПениКомиссия +
				+item.тПлатеж.Проценты
			setSum(a)
		}
	}, [item])
	return (
		<Modal
			open={visible}
			title={title}
			okText='Рассчитать'
			cancelText='Выйти'
			width={'400px'}
			onCancel={onCancel}
			onOk={() => {
				form
					.validateFields()
					.then(values => {
						const d = moment(values.prognoz).format('YYYY-MM-DD')
						console.log(d, 'D')
						dispatch(getUserData(d))
					})
					.catch(info => {
						console.log('Validate Failed:', info)
					})
			}}
			centered
		>
			<Form form={form} name='prognoz_modal'>
				<Form.Item name='prognoz'>
					<DatePicker
						locale={locale}
						format={dateFormat}
						style={{ width: '100%' }}
					/>
				</Form.Item>
				<span>Полный платеж: {makeNumberPretty(sum)} руб.</span>
			</Form>
		</Modal>
	)
}
export default memo(PrognozModal)
