import axios from 'axios'
import getDefaultHeader from '../../heplers/getDefaultHeader'

const BASE_API_PATH = import.meta.env.VITE_REACT_APP_API_URL

// Action types
const ADD_OFFICE = 'OFFICE/ Add office'

// Reducer
const inititalState = {
	data: {}
}

// Reducer
const office = (state = inititalState, action) => {
	switch (action.type) {
		case ADD_OFFICE:
			return { ...state, data: action.payload }
		default:
			return state
	}
}

// Actions
export const addOffice = office => {
	return {
		type: ADD_OFFICE,
		payload: office
	}
}

export const getOffice = () => {
	return function (dispatch) {
		axios
			.get(`${BASE_API_PATH}/get_corp_structure`, getDefaultHeader())
			.then(res => {
				dispatch(addOffice(res.data))
			})
			.catch(err => console.log(err.response))
	}
}
export default office
