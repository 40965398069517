import React, { memo, useEffect, useState } from 'react'
import Router from './router/Router'

import { notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'
import addUserToLocal from './heplers/addUserToLocal'
import getDefaultHeader from './heplers/getDefaultHeader'
import removeUserFromLocal from './heplers/removeUserFromLocal'
import { changeGlobalError } from './redux/ducks/globalError'
import { clearMessages } from './redux/ducks/messages'
import { getOffice } from './redux/ducks/office'
import { clearUserData, esiaAuth } from './redux/ducks/user'

import Loader from './common/components/Loader/Loader'
import useHistoryNavigation from './hooks/useHistoryNavigation'
import { addCOnfig } from './redux/ducks/config'

const BASE_API_PATH = import.meta.env.VITE_REACT_APP_API_URL

const App = () => {
	const globalError = useSelector(state => state.globalError)
	const dispatch = useDispatch()

	const { goToMain, goToLogin, goToEsiaRegister, history } =
		useHistoryNavigation()
	const [togs, setTogs] = useState({
		server: false,
		internet: false,
		fail: false,
		client: false
	})

	const [initLoader, setInitLoader] = useState(true)

	useEffect(() => {
		const getInitConfig = async () => {
			await axios
				.get(`${BASE_API_PATH}/get_config`, getDefaultHeader())
				.then(res => {
					localStorage.setItem(
						'conf',
						JSON.stringify({ ...res.data, timestamp: Date.now() })
					)
					dispatch(addCOnfig(res.data))
					setInitLoader(false)
				})
				.catch(err => console.log(err.response))
			// };
		}
		getInitConfig()
		dispatch(getOffice())
	}, []) // }, [dispatch]);

	useEffect(() => {
		// console.log(history.location.pathname)
		if (history.location.pathname === '/esia_result') {
			let search = history.location.search.split('=')
			// console.log(search[0].substr(1))
			if (search[0].substr(1) === 'code') {
				let code = search[1]
				// console.log(code)
				dispatch(
					esiaAuth({
						code,
						goToMain,
						addUserToLocal,
						goToEsiaRegister,
						goToLogin
					})
				)
			} else {
				goToLogin()
			}
		}
	}, [
		dispatch,
		goToEsiaRegister,
		goToLogin,
		goToMain,
		history.location.pathname,
		history.location.search
	])

	useEffect(() => {
		// console.log(globalError)
		if (globalError && globalError === 'server_error') {
			if (!togs.server) {
				setTogs({ ...togs, server: true })
				notification.error({
					message: 'Ошибка',
					description: 'Проблемы с сервером'
				})
				setTimeout(() => {
					setTogs({ ...togs, server: false })
				}, 3000)
			}
			dispatch(changeGlobalError(null))
		}
		if (globalError && globalError === 'no_internet') {
			if (!togs.internet) {
				setTogs({ ...togs, internet: true })
				notification.error({
					message: 'Ошибка',
					description:
						'Отсутствует подключение к интернету или проблемы с доступом к базе данных'
				})
				setTimeout(() => {
					setTogs({ ...togs, internet: false })
				}, 3000)
			}
			dispatch(changeGlobalError(null))
		}
		if (globalError && globalError === '1C_error') {
			if (!togs.с) {
				setTogs({ ...togs, с: true })
				notification.error({
					message: 'Ошибка',
					description: 'Проблемы с подключением к 1С'
				})
				setTimeout(() => {
					setTogs({ ...togs, с: false })
				}, 3000)
			}
			dispatch(changeGlobalError(null))
		}
		if (globalError && globalError === 'session_fail') {
			if (!togs.fail) {
				setTogs({ ...togs, fail: true })
				notification.error({
					message: 'Ошибка',
					description: 'Ваша сессия устарела'
				})
				dispatch(clearUserData())
				dispatch(clearMessages())
				removeUserFromLocal()
				goToLogin()
				setTimeout(() => {
					setTogs({ ...togs, fail: false })
				}, 3000)
			}
			dispatch(changeGlobalError(null))
		}
		if (globalError && globalError === 'no_client') {
			if (!togs.client) {
				setTogs({ ...togs, client: true })
				notification.error({
					message: 'Ошибка',
					description: 'Клиент не подключен к сервису мобильного приложения.'
				})
				setTimeout(() => {
					setTogs({ ...togs, client: false })
				}, 3000)
			}
			dispatch(changeGlobalError(null))
		}
	}, [globalError, dispatch, togs, goToLogin])

	return initLoader ? <Loader isLoading={true} /> : <Router />
	// return <Loader isLoading={true}/>
}

export default memo(App)
