import { combineReducers } from 'redux'

import config from './ducks/config'
import globalError from './ducks/globalError'
import messages from './ducks/messages'
import office from './ducks/office'
import payment from './ducks/payment'
import sms from './ducks/sms'
import user from './ducks/user'

const rootReducer = combineReducers({
	user,
	messages,
	sms,
	payment,
	globalError,
	office,
	config
})

export default rootReducer
