import axios from 'axios';
import { changeGlobalError } from './globalError';
import getDefaultObject from '../../heplers/getDefaultObject';
import getDefaultHeader from '../../heplers/getDefaultHeader';

const BASE_API_PATH = import.meta.env.VITE_REACT_APP_API_URL;

// Action types
const ADD_PAYMENT_SERVER_RESPONSE = 'PAYMENT/ Add server response';
const CLEAR_PAYMENT_SERVER_RESPONSE = 'PAYMENT/ Clear server response';

const ADD_QR = 'PAYMENT/ Add qr';
const CLEAR_QR = 'PAYMENT/ Clear qr';

// Reducer
const inititalState = {
  serverResponse: {},
  qr: null,
  link: null,
};

// Reducer
const payment = (state = inititalState, action) => {
  switch (action.type) {
    case ADD_PAYMENT_SERVER_RESPONSE:
      return {
        ...state,
        serverResponse: action.payload,
      };
    case CLEAR_PAYMENT_SERVER_RESPONSE:
      return {
        ...state,
        serverResponse: {},
      };
    case ADD_QR:
      return {
        ...state,
        qr: action.payload,
        link: action.link,
      };
    case CLEAR_QR:
      return {
        ...state,
        qr: null,
        link: null,
      };
    default:
      return state;
  }
};

export const clearPaymentServerResponse = () => {
  return {
    type: CLEAR_PAYMENT_SERVER_RESPONSE,
  };
};

export const clearQrCode = () => {
  return {
    type: CLEAR_QR,
  };
};

// Создать платеж
export const createPayment =
  ({ vals, tab }) =>
  (dispatch) => {
    const obj = {
      ...getDefaultObject(),
      ...vals,
    };

    axios
      .post(`${BASE_API_PATH}/payment/create/sberbank`, obj, getDefaultHeader())
      .then((res) => {
        if (res.data.url) {
          localStorage.setItem('tab', JSON.stringify(tab));
          window.location.replace(res.data.url);
        } else {
          dispatch({
            type: ADD_PAYMENT_SERVER_RESPONSE,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          dispatch({
            type: ADD_PAYMENT_SERVER_RESPONSE,
            payload: err.response,
          });
        } else {
          dispatch(changeGlobalError('no_internet'));
        }
      });
  };

// Подтверждение платежа
export const sendPaymentStatus =
  ({ vals, url }) =>
  (dispatch) => {
    let obj = {
      ...getDefaultObject(),
      ...vals,
    };
    axios
      .post(`${BASE_API_PATH}/payment/${url}`, obj, getDefaultHeader())
      .then((res) => {
        dispatch({
          type: ADD_PAYMENT_SERVER_RESPONSE,
          payload: { ...res, url },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          dispatch({
            type: ADD_PAYMENT_SERVER_RESPONSE,
            payload: { ...err.response, url },
          });
        } else {
          dispatch(changeGlobalError('no_internet'));
        }
      });
  };

// Создать заявку на досрочное гашение
export const createEarlyPayment = (vals) => (dispatch) => {
  const obj = {
    ...getDefaultObject(),
    ...vals,
  };

  axios
    .post(`${BASE_API_PATH}/credit/send_early_payment_request`, obj, getDefaultHeader())
    .then((res) => {
      dispatch({
        type: ADD_PAYMENT_SERVER_RESPONSE,
        payload: res,
      });
    })
    .catch((err) => {
      if (err.response) {
        console.error(err.response);
        dispatch({
          type: ADD_PAYMENT_SERVER_RESPONSE,
          payload: err.response,
        });
      } else {
        dispatch(changeGlobalError('no_internet'));
      }
    });
};

// Создать заявку на досрочное снятие
export const createEarlySaving = (vals) => (dispatch, getState) => {
  const obj = {
    ...getDefaultObject(),
    ...vals,
  };

  // console.log(obj)
  axios
    .post(`${BASE_API_PATH}/deposit/send_early_withdrawal_request`, obj, getDefaultHeader())
    .then((res) => {
      dispatch({
        type: ADD_PAYMENT_SERVER_RESPONSE,
        payload: res,
      });
      // console.log(res);
    })
    .catch((err) => {
      if (err.response) {
        console.error(err.response);
        dispatch({
          type: ADD_PAYMENT_SERVER_RESPONSE,
          payload: err.response,
        });
      } else {
        dispatch(changeGlobalError('no_internet'));
      }
    });
};

// Создать платеж PAYLER
export const createPaymentPayler =
  ({ vals, tab }) =>
  (dispatch) => {
    const obj = {
      ...getDefaultObject(),
      ...vals,
    };

    axios
      .post(`${BASE_API_PATH}/payment/create/payler`, obj, getDefaultHeader())
      .then((res) => {
        if (res.data.url) {
          let config = JSON.parse(localStorage.getItem('conf'));
          let paylerUrl =
            config.payments && config.payments.payler && config.payments.payler.payler_url
              ? config.payments.payler.payler_url
              : null;
          if (paylerUrl) {
            let link = `${paylerUrl}/gapi/Pay?session_id=${res.data.url}`;
            localStorage.setItem('tab', JSON.stringify(tab));
            window.location.replace(link);
          } else {
            dispatch({
              type: ADD_PAYMENT_SERVER_RESPONSE,
              payload: {
                error: true,
                code: 400,
                error_message: 'No payler url in configuration file',
              },
            });
          }
        } else {
          dispatch({
            type: ADD_PAYMENT_SERVER_RESPONSE,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          dispatch({
            type: ADD_PAYMENT_SERVER_RESPONSE,
            payload: err.response,
          });
        } else {
          dispatch(changeGlobalError('no_internet'));
        }
      });
  };

// Создать платеж Opening Bank
export const createOpeningPayment =
  ({ vals, tab }) =>
  (dispatch) => {
    const obj = {
      ...getDefaultObject(),
      ...vals,
    };

    axios
      .post(`${BASE_API_PATH}/payment/create/openbank`, obj, getDefaultHeader())
      .then((res) => {
        dispatch({
          type: ADD_PAYMENT_SERVER_RESPONSE,
          payload: res,
        });
        if (res.data.url) {
          localStorage.setItem('tab', JSON.stringify(tab));
          window.location.replace(res.data.url);
        } else {
          dispatch({
            type: ADD_PAYMENT_SERVER_RESPONSE,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          dispatch({
            type: ADD_PAYMENT_SERVER_RESPONSE,
            payload: err.response,
          });
        } else {
          dispatch(changeGlobalError('no_internet'));
        }
      });
  };

// Получить QR код для оплаты
export const getPaymentQR =
  ({ vals }) =>
  (dispatch) => {
    const obj = {
      ...getDefaultObject(),
      ...vals,
    };

    axios
      .post(`${BASE_API_PATH}/payment/get_qr_code`, obj, getDefaultHeader())
      .then((res) => {
        if (res.data.content && res.data.error_key === '0') {
          dispatch({
            type: ADD_QR,
            payload: res.data.content,
            link: res.data.link,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          dispatch({
            type: ADD_PAYMENT_SERVER_RESPONSE,
            payload: err.response,
          });
        } else {
          dispatch(changeGlobalError('no_internet'));
        }
      });
  };

// Создать платеж Альфабанк
export const createPaymentAlfa =
  ({ vals, tab }) =>
  (dispatch) => {
    const obj = {
      ...getDefaultObject(),
      ...vals,
    };

    axios
      .post(`${BASE_API_PATH}/payment/create/alfa`, obj, getDefaultHeader())
      .then((res) => {
        if (res.data.url) {
          let link = res.data.url;
          localStorage.setItem('tab', JSON.stringify(tab));
          window.location.replace(link);
        } else {
          dispatch({
            type: ADD_PAYMENT_SERVER_RESPONSE,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          dispatch({
            type: ADD_PAYMENT_SERVER_RESPONSE,
            payload: err.response,
          });
        } else {
          dispatch(changeGlobalError('no_internet'));
        }
      });
  };

export default payment;

