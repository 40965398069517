import React, { memo, useState, useEffect } from 'react';
import { Table, Card, Button } from 'antd';

import { useSelector } from 'react-redux';
import useWindowSize from '../../../../../hooks/useWindowSize';

import ContributionCard from '../../TableCards/ContributionCard/ContributionCard';

import makeNumberPretty from '../../../../../heplers/makeNumberPretty';
import ReactHtmlParser from 'react-html-parser';

const ReccuringContributions = () => {
  const [expandedRowKeys, changeExpandedRowKeys] = useState([]);
  const [openCard, setOpenCard] = useState([]);
  const [tableData, changeTableData] = useState([]);

  const userData = useSelector((state) => state.user.data);

  const { width } = useWindowSize();

  useEffect(() => {
    if (userData && userData.ИнформацияОВзносах) {
      if (userData.ИнформацияОВзносах.ПериодическийВзнос) {
        if (Array.isArray(userData.ИнформацияОВзносах.ПериодическийВзнос)) {
          changeTableData(userData.ИнформацияОВзносах.ПериодическийВзнос);
        } else {
          let a = [];
          a.push(userData.ИнформацияОВзносах.ПериодическийВзнос);
          changeTableData(a);
        }
      }
    }
  }, [userData]);

  const onOpenCard = (id) => {
    if (openCard.includes(id)) {
      let a = openCard.filter((el) => el !== id);
      setOpenCard(a);
    } else {
      let a = [...openCard, id];
      setOpenCard(a);
    }
  };

  const getReason = (record) => {
    let res = record.Основание ? record.Основание.Представление : '';
    if (res) {
      let a = res.split(' ');
      let b = a[0] + ' ' + a[1] + '<br/>' + a[2] + ' ' + a[3] + '<br/>' + a[4] + ' ' + a[5];
      res = b;
    }
    return <p className='table-number'>{res ? ReactHtmlParser(res) : '-'}</p>;
  };

  const columns = [
    {
      title: 'Наименование',
      key: 'number',
      responsive: ['md'],
      align: 'center',
      render: (text, record) => (
        <p className='table-number table-text'>{record.Взнос.Наименование}</p>
      ),
    },
    {
      title: 'Основание',
      key: 'status',
      responsive: ['md'],
      align: 'center',
      render: (text, record) => getReason(record),
    },
    {
      title: 'Сумма',
      key: 'sum',
      responsive: ['md'],
      align: 'center',
      render: (text, record) => (
        <p className='table-number'>{makeNumberPretty(record.ОстатокНоминал)} руб.</p>
      ),
    },
    {
      title: '',
      key: 'actions',
      responsive: ['md'],
      align: 'center',
      render: (text, record) => (
        <>
          <Button
            type='link'
            className='register-btn'
            onClick={() => {
              if (expandedRowKeys.includes(record.Взнос.UIDВзноса)) {
                onTableRowExpand(false, record);
              } else {
                onTableRowExpand(true, record);
              }
            }}
          >
            Подробнее
          </Button>
        </>
      ),
    },
  ];

  const onTableRowExpand = (expanded, item) => {
    let keys = expandedRowKeys;
    let a = [];
    let b = item.Основание ? item.Основание.UIDДокумента : item.Взнос.UIDВзноса;

    if (!keys.includes(b)) {
      a = [...keys, b];
    } else {
      a = keys.filter((el) => el !== b);
    }
    changeExpandedRowKeys(a);
  };

  return (
    <>
      {tableData.length ? (
        width >= 852 ? (
          // <div className='default-tabs-holder'>
          <Table
            className='table-width'
            rowKey={(record) =>
              record.Основание ? record.Основание.UIDДокумента : record.Взнос.UIDВзноса
            }
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ y: 'calc(100vh - 64px - 65.8px - 54.4px - 5rem)' }}
            expandable={{
              expandedRowRender: (record) => <ContributionCard record={record} colNum={12} />,
              expandedRowKeys: expandedRowKeys,
              onExpand: (a, b) => onTableRowExpand(a, b),
              expandIcons: null,
            }}
          />
        ) : (
          // </div>
          <div
            className='card-wrapper'
            // style={{ height: 'calc(100vh - 64px - 65.8px - 54.4px - 3rem)', overflow: 'auto' }}
          >
            {tableData.map((el) => {
              return (
                <Card
                  key={el.Основание ? el.Основание.UIDДокумента : el.Взнос.UIDВзноса}
                  className='card-holder'
                >
                  <div className='card-row'>
                    <span className='card-title'>Наименование</span>
                    <p className='table-number'>{el.Взнос.Наименование}</p>
                  </div>

                  <div className='card-row'>
                    <span className='card-title'>Основание</span>
                    {getReason(el)}
                  </div>

                  <div className='card-row'>
                    <span className='card-title'>Сумма</span>
                    <p className='table-number'>{makeNumberPretty(el.ОстатокНоминал)} руб.</p>
                  </div>

                  <Button
                    type='primary'
                    className='tabs-card-btn'
                    onClick={() =>
                      onOpenCard(el.Основание ? el.Основание.UIDДокумента : el.Взнос.UIDВзноса)
                    }
                  >
                    Подробнее
                  </Button>

                  <div
                    className={
                      openCard.includes(
                        el.Основание ? el.Основание.UIDДокумента : el.Взнос.UIDВзноса,
                      )
                        ? 'loan-card-holder visible'
                        : ' loan-card-holder invisible'
                    }
                  >
                    <ContributionCard record={el} colNum={24} />
                  </div>
                </Card>
              );
            })}
          </div>
        )
      ) : (
        <p className='no-data'> У Вас нет периодических взносов</p>
      )}
    </>
  );
};

export default memo(ReccuringContributions);

